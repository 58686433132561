import { IS_NO_ROBOT_INDEX } from 'src/constants';
import {
  IMAGE_DEFAULT,
  WEB_DESC,
  WEB_KEYWORD,
  WEB_NAME,
  WEB_TITLE,
  WEB_URL
} from 'src/constants/layout';

const SEO = {
  noindex: IS_NO_ROBOT_INDEX,
  nofollow: IS_NO_ROBOT_INDEX,
  title: WEB_TITLE,
  description: WEB_DESC,
  openGraph: {
    type: 'article',
    locale: 'th_TH',
    url: WEB_URL.WEB,
    title: WEB_TITLE,
    description: WEB_DESC,
    site_name: WEB_NAME,
    images: [
      {
        url: IMAGE_DEFAULT,
        width: '750',
        height: '422',
        alt: WEB_NAME
      }
    ]
  },
  // twitter: {
  //   handle: TWITTER.HANDLE,
  //   site: TWITTER.SITE,
  //   cardType: 'summary_large_image'
  // },
  additionalMetaTags: [{
    name: 'keywords',
    content: WEB_KEYWORD
  }]
};

export default SEO;
