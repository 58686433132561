import _ from 'lodash';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { decrementItem, incrementItem } from 'src/services/member-auth/utils/helper';

// entity
const dataAdapter = createEntityAdapter();

// initial state
const initialState = {
  tags: [],
  categories: [],
  articlesTags: [],
  articlesCategories: [],
  ...dataAdapter.getInitialState()
};

export const member = createSlice({
  name: 'member',
  initialState: _.cloneDeep(initialState),
  reducers: {
    initialNews: (state, action) => {
      state[action.payload.type] = action.payload.data;
    },
    initialLists: (state, action) => {
      // console.log('#1 initialLists');
      // if (!_.isEmpty(action?.payload?.data)) {
      state[action.payload.type] = [...action.payload.data];
      // }
    },
    increment: (state, action) => {
      // console.log('#1 increment');
      state[action.payload.type] = incrementItem(state[action.payload.type], action.payload.data);
    },
    specificDecrement: (state, action) => {
      // console.log('#1 specificDecrement');
      state[action.payload.type] = decrementItem(state[action.payload.type], action.payload.data);
    }
  }
});
export const { increment, specificDecrement, initialNews, initialLists } = member.actions;
export default member.reducer;
