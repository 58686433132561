import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import member from 'src/services/member-auth/redux';
import address from 'src/services/member-auth/redux/address';
import data from './data';
import navSlice from './navSlice';

const store = configureStore({
  reducer: {
    data,
    member: member,
    address: address,
    navData: navSlice
  }
});

const useAppSelector = useSelector;
const useAppDispatch = useDispatch;

export { store, useAppDispatch, useAppSelector };
